import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCollectionsManagementsIndicators } from '../../../../../../../../lib/api/collections';
import { getCollectionsDocumentIndicators } from '../../../../../../../../lib/api/collections';
import { useDispatch } from 'react-redux';
import EnhacedBox from '../../../../../../../../lib/designSystem/Box';
import CustomPieChart from './components/PieChart';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  DocumentsDialog,
  AmountsDialog,
  ManagementsDialog,
} from './components/Dialog';

const CollectionsIndicators = () => {
  const [loading, setLoading] = useState(true);
  const [documentsCountData, setDocumentsCountData] = useState([]);
  const [documentsAmountData, setDocumentsAmountData] = useState([]);
  const [showDocumentType, setShowDocumentType] = useState('count');

  const [dueByBusiness, setDueByBusiness] = useState([]);
  const [dueByStakeholder, setDueByStakeholder] = useState([]);
  const [showDueBy, setShowDueBy] = useState('business');

  const [dueManagementsData, setDueManagementsData] = useState([]);
  const [nextManagementsData, setNextManagementsData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [selectedNestedEntry, setSelectedNestedEntry] = useState<any>(null);

  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [openAmountsDialog, setOpenAmountsDialog] = useState(false);
  const [openManagementsDialog, setOpenManagementsDialog] = useState(false);

  const dispatch = useDispatch();

  const setUpCollectionsIndicators = async () => {
    setLoading(true);
    try {
      const documentsIndicators =
        await getCollectionsDocumentIndicators(dispatch);
      setDocumentsCountData(documentsIndicators.chartData.countData);
      setDocumentsAmountData(documentsIndicators.chartData.amountData);

      setDueByBusiness(documentsIndicators.chartData2.dueByBusiness);
      setDueByStakeholder(documentsIndicators.chartData2.dueByStakeholder);

      const managementsIndicators =
        await getCollectionsManagementsIndicators(dispatch);
      setDueManagementsData(managementsIndicators.chartData.dueManagementsData);
      setNextManagementsData(
        managementsIndicators.chartData.nextManagementsData,
      );
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleDialogClose = () => {
    setOpenDocumentsDialog(false);
    setOpenAmountsDialog(false);
    setOpenManagementsDialog(false);
    setSelectedNestedEntry(null);
    setSelectedEntry(null);
  };

  const handleDocumentsPieChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setOpenDocumentsDialog(true);
  };

  const handleAmountsPieChartClick = (entry: any) => {
    setSelectedEntry(entry);
    setOpenAmountsDialog(true);
  };

  const handleManagementsPieChartClick = (entry: any) => {
    console.log(entry);
    setSelectedEntry(entry);
    setOpenManagementsDialog(true);
  };

  const handleNestedPieChartClick = (entry: any) => {
    setSelectedNestedEntry(entry);
  };

  useEffect(() => {
    setUpCollectionsIndicators();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  const minHeight = 270;

  return (
    <div style={{ padding: '16px 0px' }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                {showDocumentType === 'count'
                  ? 'Documentos por vencer'
                  : 'Monto por vencer'}
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={
                    showDocumentType === 'count'
                      ? documentsCountData
                      : documentsAmountData
                  }
                  onClick={handleDocumentsPieChartClick}
                  money={showDocumentType === 'amount'}
                  colors={[
                    '#14BA25',
                    '#E7EB2A',
                    '#DB6723',
                    '#E14F2C',
                    '#E83832',
                    '#D62839',
                  ]}
                />
              </div>
              <Typography
                variant="body2"
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: 'blue',
                }}
                onClick={() =>
                  setShowDocumentType(
                    showDocumentType === 'count' ? 'amount' : 'count',
                  )
                }
              >
                {showDocumentType === 'count'
                  ? 'Ver por monto'
                  : 'Ver por documentos'}
              </Typography>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                {showDueBy === 'business'
                  ? 'Monto vencido por cliente'
                  : 'Monto vencido por deudor'}
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={
                    showDueBy === 'business' ? dueByBusiness : dueByStakeholder
                  }
                  onClick={handleAmountsPieChartClick}
                  money
                  colors={['#DB6723', '#E14F2C', '#E83832', '#D62839']}
                />
              </div>
              <Typography
                variant="body2"
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: 'blue',
                }}
                onClick={() =>
                  setShowDueBy(
                    showDueBy === 'business' ? 'stakeholder' : 'business',
                  )
                }
              >
                {showDueBy === 'business'
                  ? 'Ver por deudor'
                  : 'Ver por cliente'}
              </Typography>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                Gestiones vencidas
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={dueManagementsData}
                  onClick={handleManagementsPieChartClick}
                  colors={['#DB6723', '#D62839']}
                />
              </div>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={3}>
          <EnhacedBox>
            <div style={{ minHeight }}>
              <Typography
                variant="body1"
                textAlign={'center'}
                fontWeight={'bold'}
              >
                Gestiones por vencer
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={nextManagementsData}
                  onClick={handleManagementsPieChartClick}
                  colors={['#14BA25', '#E7EB2A']}
                />
              </div>
            </div>
          </EnhacedBox>
        </Grid>
      </Grid>
      <DocumentsDialog
        openDialog={openDocumentsDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
        selectedNestedEntry={selectedNestedEntry}
        handleNestedPieChartClick={handleNestedPieChartClick}
      />
      <AmountsDialog
        openDialog={openAmountsDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
      />
      <ManagementsDialog
        openDialog={openManagementsDialog}
        handleDialogClose={handleDialogClose}
        selectedEntry={selectedEntry}
        selectedNestedEntry={selectedNestedEntry}
      />
    </div>
  );
};

export default CollectionsIndicators;
