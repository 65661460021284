import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../lib/designSystem/HeaderWithActions';
import { getCompanyNetwork } from '../../../../lib/api';
import { useDispatch } from 'react-redux';
import CustomAlert from '../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import NetworkBusinessDetails from './components/Details';
import DocumentSearcher from '../../../../lib/common/DocumentSearcher';
import TableWithPagination from '../../../../lib/designSystem/TableWithPagination';
import { loggerDev } from '../../../../lib/functions/utils';

const headers = [
  { key: 'name', label: 'Empresa' },
  { key: 'identifier', label: 'Rut' },
];

const NetworkBusinesses: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [business, setBusiness] = useState<any>(null);
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });
  const [businessIdentifier, setBusinessIdentifier] = useState('');

  const setSuccessOpen = (message: string) => {
    setSuccessMessage(message);
    setOpenSuccess(true);
  };

  const setFailureOpen = (message: string) => {
    setFailureMessage(message);
    setOpenFailure(true);
  };

  // const handleCreateNewBusiness = async () => {
  //   setOpenModal(true);
  // };

  const setUp = async () => {
    try {
      const businessesResponse = await getCompanyNetwork(dispatch, '');
      setBusinesses(businessesResponse.data);
      setPagination(businessesResponse.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleDetails = (id: number) => {
    loggerDev('id', id);
    setBusiness(businesses.find((x: any) => x.id === id));
    setOpenModal(true);
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Ver',
    },
  ];

  const generateQuery = ({
    page,
    pageSize,
    businessIdentifier,
  }: {
    page: number;
    pageSize: number;
    businessIdentifier: string;
  }) => {
    let query = '';
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (page && pageSize) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const searchNetwork = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({
        page,
        pageSize,
        businessIdentifier,
      });
      const response = await getCompanyNetwork(dispatch, query);
      setBusinesses(response.data);
      setPagination(response.pagination);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const documentSearcher = (
    <div>
      <DocumentSearcher
        searchData={searchNetwork}
        setData={setBusinesses}
        setLoading={setLoading}
        businessIdentifier={businessIdentifier}
        setBusinessIdentifier={setBusinessIdentifier}
        pagination={pagination}
      />
    </div>
  );

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        type="subHeader"
        title="Esta es toda tu red de empresas."
        variant="body1"
        buttons={[]}
        item={documentSearcher}
      />

      <TableWithPagination
        dataHeaders={headers}
        searchData={searchNetwork}
        data={businesses}
        setData={setBusinesses}
        showActions
        rowsPerPageDefault={PAGE_SIZE}
        actions={actions}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openModal && (
        <NetworkBusinessDetails
          open={openModal}
          setOpen={setOpenModal}
          business={business}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      )}
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        text={successMessage}
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailure}
        setOpen={setOpenFailure}
        severity="error"
        text={failureMessage}
        handleClose={() => setOpenFailure(false)}
      />
    </div>
  );
};

export default NetworkBusinesses;
