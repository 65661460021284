import React from 'react';
import CustomPieChart from '../PieChart';
import EnhancedTable from '../../../../../../../../../../lib/designSystem/Table';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';

const greenPalette = [
  '#14ba25', // original color
  '#2ebc2f', // slightly lighter
  '#1f9e26', // darker tone
  '#149c21', // deeper green
  '#20d84f', // brighter green
  '#1e8e21', // muted green
];

const yellowPalette = [
  '#e7eb2a', // original color
  '#f7f9a8', // Pale Lemon
  '#f0f371', // Soft Sun
  '#d1c924', // Goldenrod Yellow
  '#b89f1e', // Mustard Yellow
  '#8f7318', // Dark Amber
];

const orangePalette = [
  '#db6723', // original color
  '#e87b3a', // slightly lighter
  '#c85f1f', // darker tone
  '#f27a2e', // brighter orange
  '#d96d2a', // muted orange
  '#ff8c47', // vibrant orange
];

const redPalette = [
  '#D62839', // original color
  '#E03C48', // slightly lighter
  '#B7202C', // darker tone
  '#F03D48', // brighter red
  '#D92236', // muted red
  '#F74F5A', // vibrant red
];

const paletteMap = {
  '#D62839': redPalette,
  '#E7EB2A': yellowPalette,
  '#DB6723': orangePalette,
  '#14BA25': greenPalette,
};

export const DocumentsDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
  selectedNestedEntry,
  handleNestedPieChartClick,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
  selectedNestedEntry: any;
  handleNestedPieChartClick: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>
        {`${selectedEntry?.name}${selectedNestedEntry ? ` - ${selectedNestedEntry?.name}` : ''}`}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={selectedNestedEntry ? 4 : 12}
              sm={selectedNestedEntry ? 4 : 12}
            >
              <Typography align="center">
                Selecciona para más detalle
              </Typography>
            </Grid>
            <Grid
              item
              xs={selectedNestedEntry ? 4 : 0}
              sm={selectedNestedEntry ? 4 : 0}
            >
              <Typography align="center">
                {selectedNestedEntry ? `Por cliente` : ''}
              </Typography>
            </Grid>
            <Grid
              item
              xs={selectedNestedEntry ? 4 : 0}
              sm={selectedNestedEntry ? 4 : 0}
            >
              <Typography align="center">
                {selectedNestedEntry ? 'Por pagador' : ''}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            {selectedEntry?.collectorChart && (
              <CustomPieChart
                data={selectedEntry?.collectorChart}
                money={selectedEntry?.money}
                onClick={handleNestedPieChartClick}
                colors={['#db6723', '#e14f2c', '#e83832', '#D62839']}
              />
            )}
            {selectedNestedEntry && (
              <CustomPieChart
                data={selectedNestedEntry?.byBusinessChart}
                money={selectedEntry?.money}
                colors={['#db6723', '#e14f2c', '#e83832', '#D62839']}
              />
            )}
            {selectedNestedEntry && (
              <CustomPieChart
                data={selectedNestedEntry?.byStakeholderChart}
                money={selectedEntry?.money}
                colors={['#db6723', '#e14f2c', '#e83832', '#D62839']}
              />
            )}
          </div>
          <EnhancedTable
            dataHeaders={[
              { key: 'collectorName', label: 'Ejecutivo' },
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'stakeholderName', label: 'Pagador' },
              {
                key: 'paymentDate',
                label: 'Vencimiento',
                type: 'date',
              },
              { key: 'amount', label: 'Monto', type: 'money' },
            ]}
            data={selectedEntry?.documents || []}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const AmountsDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
}) => {
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>{selectedEntry?.name}</DialogTitle>
      <DialogContent>
        <EnhancedTable
          dataHeaders={[
            { key: 'collectorName', label: 'Ejecutivo' },
            { key: 'folio', label: 'Folio' },
            { key: 'businessName', label: 'Cliente' },
            { key: 'stakeholderName', label: 'Pagador' },
            {
              key: 'paymentDate',
              label: 'Vencimiento',
              type: 'date',
            },
            { key: 'amount', label: 'Monto', type: 'money' },
          ]}
          data={selectedEntry?.documents || []}
        />
      </DialogContent>
    </Dialog>
  );
};

export const ManagementsDialog = ({
  openDialog,
  handleDialogClose,
  selectedEntry,
  selectedNestedEntry,
}: {
  openDialog: boolean;
  handleDialogClose: () => void;
  selectedEntry: any;
  selectedNestedEntry: any;
}) => {
  console.log(selectedEntry);
  console.log(paletteMap[selectedEntry?.color as keyof typeof paletteMap]);
  return (
    <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="lg">
      <DialogTitle>
        {`${selectedEntry?.name}${selectedNestedEntry ? ` - ${selectedNestedEntry?.name}` : ''}`}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {selectedEntry?.collectorChart && (
            <CustomPieChart
              data={selectedEntry?.collectorChart}
              money={selectedEntry?.money}
              colors={
                paletteMap[selectedEntry?.color as keyof typeof paletteMap] || [
                  '#DB6723',
                  '#E14F2C',
                  '#E83832',
                  '#D62839',
                ]
              }
            />
          )}
          <EnhancedTable
            dataHeaders={[
              { key: 'id', label: 'ID' },
              { key: 'collectorName', label: 'Ejecutivo' },
              { key: 'type', label: 'Tipo' },
              { key: 'folio', label: 'Folio' },
              { key: 'businessName', label: 'Cliente' },
              { key: 'stakeholderName', label: 'Pagador' },
              { key: 'dueDate', label: 'Vencimiento', type: 'date' },
              { key: 'createdAt', label: 'Creado', type: 'date' },
            ]}
            data={selectedEntry?.documents || []}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
