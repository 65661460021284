import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../lib/designSystem/Table';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import NewSync from './components/NewSync';
import { validatePermissions } from '../../lib/functions/utils';
import { AuthState } from '../../services/redux/authSlice';
import { getAllSycronizations } from '../../lib/api/pruffRush';
import DateSearcher from '../../lib/common/DateSearcher';
import dayjs, { Dayjs } from 'dayjs';
const headers = [
  // { key: 'timestamp', label: 'Id' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'scrapingId', label: 'Sincronización' },
  { key: 'userName', label: 'Usuario' },
  { key: 'date', label: 'Fecha', type: 'date-large' },
];

const PruffRushSyncs: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [syncs, setSyncs] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const startDateDefault = new Date();
  startDateDefault.setDate(startDateDefault.getDate() - 7);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(startDateDefault),
  );
  const endDateDefault = new Date(new Date().setDate(new Date().getDate()));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(endDateDefault));

  const setSuccessOpen = (message: string) => {
    setSuccessMessage(message);
    setOpenSuccess(true);
  };

  const setFailureOpen = (message: string) => {
    setFailureMessage(message);
    setOpenFailure(true);
  };

  const handleCreateNewBusiness = async () => {
    setOpenModal(true);
  };

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nueva sincronización',
      action: () => handleCreateNewBusiness(),
      color: 'primary',
      disabled: !validatePermissions(user, 'pruffRush_execute'),
    },
  ];

  const buildQuery = () => {
    if (!startDate || !endDate) return '';
    return `startDate=${startDate}&endDate=${endDate}`;
  };
  const setUp = async () => {
    try {
      setLoading(true);
      const query = buildQuery();
      const evaluationsResponse = await getAllSycronizations(dispatch, query);
      setSyncs(evaluationsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions buttons={[]} title="Sincronizador" />
      <HeaderWithActions
        type="subHeader"
        title="Acá podrás ver todas las sincronizaciones realizadas."
        variant="body1"
        buttons={[
          {
            name: 'Buscar',
            action: () => {},
            color: 'primary',
            customButton: (
              <DateSearcher
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                searchData={setUp}
                setData={setSyncs}
                setLoading={setLoading}
              />
            ),
          },
          ...buttons,
        ]}
      />

      <EnhancedTable
        dataHeaders={headers}
        data={syncs}
        showActions
        rowsPerPageDefault={50}
        exportToFile="xlsx"
      />
      {openModal && (
        <NewSync
          type="creditLine"
          open={openModal}
          setOpen={setOpenModal}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      )}

      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        title="Éxito"
        text={successMessage}
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailure}
        setOpen={setOpenFailure}
        severity="error"
        title="Error"
        text={failureMessage}
        handleClose={() => setOpenFailure(false)}
      />
    </div>
  );
};

export default PruffRushSyncs;
