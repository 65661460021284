import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { calculateAmortization } from './helpers/calculateAmotization';
import AmortizationSchedule from './components/AmortizationSchedule';
import Graph from './components/Graph';
import Params from './components/Params';
import Summary from './components/Summary';

const CreditSimulator = (props: any) => {
  const {
    creditDetails,
    setCreditDetails,
    amortizationSchedule,
    setAmortizationSchedule,
    editing,
    documents,
  } = props;
  const [workingDayActive, setWorkingDayActive] = useState<
    'active' | 'inactive'
  >('inactive');
  const [resultView, setResultView] = useState('summary');

  useEffect(() => {
    calculateAmortization({
      creditDetails,
      setAmortizationSchedule,
      workingDayActive,
    });
  }, [
    creditDetails.amount,
    creditDetails.months,
    creditDetails.operationCost,
    creditDetails.otherExpenses,
    creditDetails.firstPaymentDate,
    creditDetails.rate,
    creditDetails.interestType,
    creditDetails.operationDate,
    workingDayActive,
  ]);

  useEffect(() => {
    calculateAmortization({
      creditDetails,
      setAmortizationSchedule,
      workingDayActive,
      documents,
    });
  }, []);

  return (
    <div>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {editing
          ? 'Ingresa los parámetros del crédito'
          : 'Estos son los datos del crédito'}
      </Typography>
      <Params
        creditDetails={creditDetails}
        setCreditDetails={setCreditDetails}
        editing={editing}
        workingDayActive={workingDayActive}
        setWorkingDayActive={setWorkingDayActive}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          content: 'center',
        }}
      >
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          Resultados de la simulación |{' '}
          <span style={{ color: 'grey' }}>Descargar</span>
        </Typography>
        <div style={{ display: 'flex', gap: 10 }}>
          {resultView !== 'summary' && (
            <Typography
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
              variant="body1"
              onClick={() => setResultView('summary')}
            >
              Ver resumen
            </Typography>
          )}
          {resultView !== 'cuotas' && (
            <Typography
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
              variant="body1"
              onClick={() => setResultView('cuotas')}
            >
              Ver cuotas
            </Typography>
          )}
          {resultView !== 'graph' && (
            <Typography
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
              variant="body1"
              onClick={() => setResultView('graph')}
            >
              Ver gráfico
            </Typography>
          )}
        </div>
      </div>
      {resultView === 'summary' && (
        <Summary
          creditDetails={creditDetails}
          amortizationSchedule={amortizationSchedule}
        />
      )}
      {resultView === 'graph' && (
        <Graph amortizationSchedule={amortizationSchedule} />
      )}
      {resultView === 'cuotas' && (
        <AmortizationSchedule amortizationSchedule={amortizationSchedule} />
      )}
    </div>
  );
};

export default CreditSimulator;
