import { requestHandler } from '../../api';

export const createFactoringRequest = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/factoring/request`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteFactoringRequest = async (dispatch: any, id: any) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/factoring/request/${id}`,
    dispatch,
  });
  if (response) return response.data;
};

export const updateStatusFactoringRequest = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/request/${id}/updateStatus`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const editParamsOnReview = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/request/${id}/inReview/editParams`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const editQuoteComitee = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/factoring/quote/${id}/comitee`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteQuoteApproval = async (
  dispatch: any,
  id: number,
  approvalId: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/factoring/quote/${id}/approval/${approvalId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getQuoteApprovals = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/factoring/quote/${id}/approval`,
    dispatch,
  });
  if (response) return response.data;
};

export const addOwnCompanyConfirmationsToDocuments = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/users/factoring/documents/addOwnConfirmation`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const activateNullFactoringRequest = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/factoring/request/${id}/payments/activateNull`,
    data,
    dispatch,
  });
  if (response) return response.data;
};
