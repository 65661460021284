import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  createBlacklist,
  deleteBlacklist,
  getBlacklist,
  updateBlacklist,
} from '../../../../../../lib/api';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import TableWithPagination from '../../../../../../lib/designSystem/TableWithPagination';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { validaRut, showAlert } from '../../../../../../lib/functions/utils';
import DocumentSearcher from '../../../../../../lib/common/DocumentSearcher';

interface BlacklistItem {
  id: number;
  businessName: string;
  businessIdentifier: string;
  reason: string;
}

const Blacklist = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [blacklist, setBlacklist] = useState<BlacklistItem[]>([]);
  const [currentBusinessName, setCurrentBusinessName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [reason, setReason] = useState('');
  const [businessIdentifierSearch, setBusinessIdentifierSearch] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editId, setEditId] = useState(0);
  const [newReason, setNewReason] = useState('');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (businessIdentifierSearch) {
      query = `businessIdentifier=${businessIdentifierSearch}&`;
    }
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };

  const fetchBlacklist = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const response = await getBlacklist(dispatch, query);
      setBlacklist(response.data);
      setPagination(response.pagination);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setBusinessName('');
    setIdentifier('');
    setReason('');
  };

  const handleSubmit = async () => {
    if (!identifier || !businessName || !reason)
      return alert('Completa todos los campos');
    if (!validaRut(identifier)) {
      alert('Rut inválido');
      return;
    }
    setLoading(true);
    try {
      await createBlacklist(dispatch, {
        businessIdentifier: identifier,
        businessName: businessName,
        reason: reason,
      });
      await fetchBlacklist({ page: 0, pageSize: PAGE_SIZE });
      showAlert(dispatch, 'success', 'Empresa agregada a la blacklist');
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        showAlert(dispatch, 'error', error?.response?.data);
      } else {
        showAlert(dispatch, 'error', 'Hubo un error');
      }
      setLoading(false);
    }
    handleCloseDialog();
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteBlacklist(dispatch, { id });
      await fetchBlacklist({ page: 0, pageSize: PAGE_SIZE });
      showAlert(dispatch, 'success', 'Empresa eliminada de la blacklist');
      setLoading(false);
    } catch (error) {
      showAlert(dispatch, 'error', 'Hubo un error');
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      await updateBlacklist(dispatch, { id: editId, reason: newReason });
      await fetchBlacklist({ page: 0, pageSize: PAGE_SIZE });
      showAlert(dispatch, 'success', 'Razón actualizada');
    } catch (error) {
      showAlert(dispatch, 'error', 'Hubo un error');
    } finally {
      setLoading(false);
      handleCloseEditDialog();
    }
  };

  const handleOpenEditDialog = (id: number) => {
    setOpenEditDialog(true);
    setEditId(id);
    setCurrentBusinessName(
      blacklist.find((item: BlacklistItem) => item.id === id)?.businessName ||
        '',
    );
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setNewReason('');
  };

  useEffect(() => {
    fetchBlacklist({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  const startButtons: CustomButtonProps[] = [
    {
      action: () => setOpenDialog(true),
      name: 'Agregar',
      color: 'primary',
    },
    {
      name: 'Filtro',
      action: () => {},
      color: 'primary',
      customButton: (
        <DocumentSearcher
          searchData={fetchBlacklist}
          setData={setBlacklist}
          setLoading={setLoading}
          properties={[
            {
              label: 'RUT Empresa',
              value: businessIdentifierSearch,
              setter: setBusinessIdentifierSearch,
              type: 'text',
            },
          ]}
          pagination={pagination}
        />
      ),
    },
  ];

  const actions = [
    { action: handleDelete, actionLabel: 'Eliminar' },
    { action: handleOpenEditDialog, actionLabel: 'Editar' },
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        subHeader
        buttons={startButtons}
        title="Acá encontrarás todas las empresas que estén dentro de tu blacklist"
      />
      <TableWithPagination
        dataHeaders={[
          { key: 'businessName', label: 'Nombre empresa' },
          { key: 'businessIdentifier', label: 'RUT' },
          { key: 'reason', label: 'Razón' },
        ]}
        actions={actions}
        data={blacklist}
        loading={loading}
        setData={setBlacklist}
        searchData={fetchBlacklist}
        setPagination={setPagination}
        pagination={pagination}
      />
      {loading && <CenteredCircularProgress relative />}
      <Dialog
        open={openDialog}
        onClose={() => handleCloseDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Agregar empresa</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Agrega una empresa a tu blacklist
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="businessName"
            label="Nombre de la empresa"
            type="text"
            fullWidth
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="identifier"
            label="RUT de la empresa"
            type="text"
            fullWidth
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value.toUpperCase())}
          />
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Razón"
            type="text"
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenDialog(false)} color="secondary">
            Cancelar
          </CustomButton>
          <CustomButton onClick={() => handleSubmit()} color="primary">
            Agregar
          </CustomButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={() => handleCloseEditDialog()}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Editar razón de {currentBusinessName}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="newReason"
            label="Razón"
            type="text"
            fullWidth
            value={newReason}
            onChange={(e) => setNewReason(e.target.value)}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            onClick={() => handleCloseEditDialog()}
            color="secondary"
          >
            Cancelar
          </CustomButton>
          <CustomButton onClick={() => handleEdit()} color="primary">
            Guardar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Blacklist;
