import React from 'react';
import { Grid, Typography } from '@mui/material';
import SimulationPreview from '../SimulationPreview';
import DocumentsPreview from '../documentsPreview';

const SimulationDoneFrozen = (props: any) => {
  const { selectedRequest, infoDiv, noInvoices, setSelectedRequest } = props;

  const requestConfiguration = {
    expensesHasIVA: selectedRequest.expensesIVA,
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <div>
            <div
              style={{
                padding: '0px 0px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography fontWeight="bold" variant="h6">
                Resumen
              </Typography>
            </div>
            {infoDiv}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ padding: '0px 0px 0px 18px' }}>
            <SimulationPreview
              selectedRequest={selectedRequest}
              setSelectedRequest={setSelectedRequest}
              requestConfiguration={requestConfiguration}
            />
          </div>
        </Grid>
      </Grid>
      {!noInvoices && (
        <DocumentsPreview
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
        />
      )}
    </div>
  );
};

export default SimulationDoneFrozen;
