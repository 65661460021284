import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  DialogActions,
  Typography,
  Grid,
  Dialog,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import CustomButton from '../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../lib/functions/utils';
import { getDocumentWithExtensionData } from '../../../../../../../lib/api/extension/factoring';
import { getCompanyFunds } from '../../../../../../../lib/api';
import {
  createFactoringReSellBatch,
  getDocumentsAvailableForReSelling,
} from '../../../../../../../lib/api/reSell/factoring';
import ResellsTable from './components/ResellsTable';
import HeaderWithActions from '../../../../../../../lib/designSystem/HeaderWithActions';
import MultiResellDocumentSelector from './components/addDocuments';
import { getCompanyConfig } from '../../../../../../../lib/api/company';
import { getOwnBankAccounts } from '../../../../../../../lib/api/bankAccounts';

const MultiResellMain = (props: any) => {
  const { open, setOpen, setSuccess, setUp, setFailure, pagination } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [funds, setFunds] = useState<any>([]);
  const [resells, setResells] = useState<any>([]);
  const [openDocumentSelector, setOpenDocumentSelector] = useState(false);
  const [canForcePaymentDate, setCanForcePaymentDate] = useState(false);
  const [bankAccountId, setBankAccountId] = useState('');
  const [bankAccounts, setBankAccounts] = useState<any>([]);

  const validParams = () => {
    for (let index = 0; index < resells.length; index++) {
      const resell = resells[index];
      if (!resell.startDate) {
        alert(
          `La factura con folio ${resell.folio} no tiene una fecha de inicio`,
        );
        return false;
      }
      if (!resell.endDate) {
        alert(
          `La factura con folio ${resell.folio} no tiene una fecha de término`,
        );
        return false;
      }
      if (!resell.rate) {
        alert(`La factura con folio ${resell.folio} no tiene una tasa`);
        return false;
      }
      if (!resell.fundId) {
        alert(`La factura con folio ${resell.folio} no tiene un fondo`);
        return false;
      }
    }
    return true;
  };

  const getDocumentData = async (documentId: string) => {
    const response = await getDocumentWithExtensionData(dispatch, documentId);
    const newResell = {
      documentId,
      ...response,
      businessName: response.business.name,
      stakeholderName: response.stakeholder.name,
      tempStartDate: formatDate(new Date()),
      startDate: formatDate(new Date()),
      tempEndDate: formatDate(new Date()),
      endDate: formatDate(new Date()),
      rate: 0,
      rateCost: 0,
      fundId: null,
      observation: '',
    };
    return newResell;
  };

  const handleSearchData = async () => {
    setLoading(true);
    setOpenDocumentSelector(false);
    try {
      const newResells = await Promise.all(
        documents.map(async (documentId: string) => {
          const newResell = await getDocumentData(documentId);
          return newResell;
        }),
      );
      console.log(newResells);
      const oldResells = [...resells];
      setResells([...oldResells, ...newResells]);
      setDocuments([]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!validParams()) {
      return;
    }
    setLoading(true);
    try {
      const payload = resells.map((resell: any) => ({
        documentId: resell.documentId,
        rate: parseFloat(resell.rate),
        rateCost: parseFloat(resell.rateCost),
        endDate: new Date(resell.endDate),
        fundId: resell.fundId,
        operationCostFixed: 0,
        operationCostVariable: 0,
        startDate: new Date(resell.startDate),
        bankAccountReceiverId: bankAccountId,
      }));
      await createFactoringReSellBatch(dispatch, { resells: payload });
      setOpen(false);
      setSuccess('Venta creada correctamente');
      await setUp({ page: pagination.page, pageSize: pagination.pageSize });
    } catch (error) {
      console.log(error);
      setFailure('Error al crear la venta');
    }
    setLoading(false);
  };

  const setUpSuggestions = async () => {
    setLoading(true);
    try {
      const response = await getDocumentsAvailableForReSelling(dispatch);
      setSuggestions(response.documentsProcessed);
      const fundsResponse = await getCompanyFunds(dispatch);
      setFunds(fundsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpConfig = async () => {
    try {
      const response = await getCompanyConfig(
        dispatch,
        'system_operations_fund_forcePaymentDate',
      );
      if (response === '1') {
        setCanForcePaymentDate(true);
      }
      const bankAccountsResponse = await getOwnBankAccounts(dispatch);
      setBankAccounts(bankAccountsResponse);
    } catch (error) {
      console.error('Error fetching date restrictions config', error);
    }
  };

  const setUpDialog = async () => {
    await setUpSuggestions();
    await setUpConfig();
  };

  useEffect(() => {
    setUpDialog();
  }, []);

  const suggestionsAvailables = suggestions.filter(
    (suggestion: any) =>
      !resells.some((resell: any) => resell.documentId === suggestion.id),
  );

  return (
    <Dialog maxWidth="xl" fullWidth open={open}>
      <div style={{ padding: 20, minHeight: 200 }}>
        {loading ? (
          <CenteredCircularProgress type="dialog" />
        ) : (
          <div>
            <HeaderWithActions title="Vender facturas" />
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={3}>
                    <Typography>
                      <b>Facturas:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{resells.length}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>Tasa promedio:</b>{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>
                      {resells.reduce(
                        (acc: any, resell: any) => acc + resell.rate,
                        0,
                      ) / resells.length || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>
                      <b>Diferencia de precio:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>
                      {formatMoney(
                        resells.reduce(
                          (acc: any, resell: any) => acc + resell.rateCost,
                          0,
                        ),
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <CustomButton
                  onClick={() => setOpenDocumentSelector(true)}
                  color="primary"
                  disabled={!document}
                >
                  Agregar
                </CustomButton>
              </Grid>
            </Grid>
            <div style={{ padding: '10px 0px' }}>
              <ResellsTable
                resells={resells}
                setResells={setResells}
                funds={funds}
                canForcePaymentDate={canForcePaymentDate}
              />
            </div>
            <div
              style={{
                padding: '20px 0px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography variant="body1">
                  Debes seleccionar la cuenta bancaria dónde te fue depositado
                  el pago
                </Typography>
              </div>
              <div style={{ width: '200px' }}>
                <FormControl fullWidth>
                  <InputLabel>Cuenta bancaria</InputLabel>
                  <Select
                    label="Cuenta bancaria"
                    value={bankAccountId}
                    onChange={(e) => setBankAccountId(e.target.value)}
                  >
                    {bankAccounts.map((bankAccount: any) => (
                      <MenuItem key={bankAccount.id} value={bankAccount.id}>
                        {bankAccount.bankName} - {bankAccount.accountNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <DialogActions>
              <CustomButton onClick={() => setOpen(false)} color="secondary">
                Salir
              </CustomButton>
              <CustomButton
                onClick={() => handleSubmit()}
                color="primary"
                disabled={resells.length === 0 || !bankAccountId}
              >
                Confirmar
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </div>
      <MultiResellDocumentSelector
        suggestions={suggestionsAvailables}
        documents={documents}
        setDocuments={setDocuments}
        handleConfirm={handleSearchData}
        open={openDocumentSelector}
        setOpen={setOpenDocumentSelector}
      />
    </Dialog>
  );
};

export default MultiResellMain;
