import React from 'react';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import InforPreview from '../../../../../../../../../common/InforPreview';
import DialogActionsWrapper from '../DialogActionWrapper';

const ShoppingCartFactoringAccepted = (props: any) => {
  const { setOpenShoppingCart, selectedRequest, setSelectedRequest, setUp } =
    props;

  const infoDiv = (
    <InforPreview
      requestType="factoring"
      type="Recieved"
      status="Aceptada"
      selectedRequest={selectedRequest}
      setSelectedRequest={setSelectedRequest}
      setUp={setUp}
    />
  );

  console.log(selectedRequest);

  return (
    <div>
      <SimulationDoneFrozen
        selectedRequest={selectedRequest}
        infoDiv={infoDiv}
      />
      <DialogActionsWrapper
        type="factoring"
        onClose={() => setOpenShoppingCart(false)}
        selectedRequest={selectedRequest}
        status={selectedRequest.status}
        setUp={setUp}
      />
    </div>
  );
};

export default ShoppingCartFactoringAccepted;
