import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import { getCompanyPayIns } from '../../../../../../lib/api/conciliations';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import Payment from './components/Payment';
import BankAccountDialog from '../../../../../../lib/common/BankAccountDialog';
import PaymentConciliationDialog from './components/Conciliation';
import {
  formatDate,
  validatePermissions,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import TableWithPagination from '../../../../../../lib/designSystem/TableWithPagination';
import DocumentSearcher from '../../../../../../lib/common/DocumentSearcher';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'businessOriginName', label: 'Cuenta Origen' },
  { key: 'businessOriginIdentifier', label: 'Rut Origen' },
  { key: 'businessDestinationName', label: 'Cuenta Destino' },
  { key: 'businessDestinationIdentifier', label: 'Rut Destino' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'amountConciliated', label: 'Monto conciliado', type: 'money' },
  { key: 'statusTranslated', label: 'Estado', type: 'conciliation-status' },
  { key: 'createdAt', label: 'Fecha de creación', type: 'date' },
];

const PayIns: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [payIn, setPayIn] = useState<any>({});
  const [payIns, setPayIns] = useState<any[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [failure, setFailure] = useState(false);
  const [failureText, setFailureText] = useState('');
  const [openConciliationDialog, setOpenConciliationDialog] = useState(false);
  const [status, setStatus] = useState('PENDING');
  const statusOptions = [
    { label: 'Pendiente', value: 'PENDING' },
    { label: 'Conciliado', value: 'CONCILIATED' },
  ];
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [stakeholderIdentifier, setStakeholderIdentifier] = useState('');
  const [businessIdentifier, setBusinessIdentifier] = useState('');
  const PAGE_SIZE = 10;
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  });

  const generateQuery = ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    let query = '';
    if (status) query += `status=${status}&`;
    if (startDate) query += `startDate=${startDate}&`;
    if (endDate) query += `endDate=${endDate}&`;
    if (stakeholderIdentifier)
      query += `stakeholderIdentifier=${stakeholderIdentifier}&`;
    if (businessIdentifier)
      query += `businessIdentifier=${businessIdentifier}&`;
    if (page) query += `page=${page}&pageSize=${pageSize}`;
    return query;
  };
  const setSuccessOpen = (text: string) => {
    setSuccessText(text);
    setSuccess(true);
  };

  const setFailureOpen = (text: string) => {
    setFailureText(text);
    setFailure(true);
  };

  const handleConciliate = (id: number) => {
    const payIn = payIns.find((x) => x.id === id);
    if (payIn) {
      setPayIn(payIn);
      setOpenConciliationDialog(true);
    }
  };

  const handleNewPayment = () => {
    const newPayment = {
      amount: 0,
      bankAccountOriginId: 0,
      bankAccountDestinationId: 0,
      createdAt: formatDate(new Date()),
    };
    setPayIn(newPayment);
    setOpenPaymentModal(true);
  };

  const canShowConciliate = () => {
    if (validatePermissions(user, 'financing_payIn_conciliate')) return true;

    return false;
  };

  const actions = [
    {
      action: handleConciliate,
      actionLabel: 'Conciliación',
      canShow: canShowConciliate,
    },
  ];

  const fetchPayIns = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setLoading(true);
    try {
      const query = generateQuery({ page, pageSize });
      const payInsResponse = await getCompanyPayIns(dispatch, query);
      setPayIns(payInsResponse.data);
      setPagination(payInsResponse.pagination);
    } catch (error) {
      setFailureOpen('No se pudieron obtener los pagos');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPayIns({ page: 0, pageSize: PAGE_SIZE });
  }, []);

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nuevo pago',
      action: () => handleNewPayment(),
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_payIn_new'),
    },
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá podrás ver todos los pagos recibidos a tus cuentas registradas."
        variant="body1"
        buttons={[
          {
            name: 'Filtro',
            action: () => {},
            color: 'primary',
            customButton: (
              <DocumentSearcher
                searchData={fetchPayIns}
                setData={setPayIns}
                setLoading={setLoading}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                stakeholderIdentifier={stakeholderIdentifier}
                setStakeholderIdentifier={setStakeholderIdentifier}
                businessIdentifier={businessIdentifier}
                setBusinessIdentifier={setBusinessIdentifier}
                pagination={pagination}
                status={status}
                setStatus={setStatus}
                statusOptions={statusOptions}
              />
            ),
          },
          ...buttons,
        ]}
      />
      <TableWithPagination
        dataHeaders={headers}
        data={payIns}
        rowsPerPageDefault={100}
        actions={actions}
        showActions
        exportToFile="xlsx"
        setData={setPayIns}
        searchData={fetchPayIns}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openPaymentModal && (
        <Payment
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          payIn={payIn}
          setPayIn={setPayIn}
          setOpenBankAccountModal={setOpenBankAccountModal}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={fetchPayIns}
          pagination={pagination}
        />
      )}
      {openBankAccountModal && (
        <BankAccountDialog
          open={openBankAccountModal}
          setOpen={setOpenBankAccountModal}
          selectedBankAccount={selectedBankAccount}
          setSelectedBankAccount={setSelectedBankAccount}
          handleSuccessExit={() => setOpenPaymentModal(true)}
          setSuccess={setSuccessOpen}
          setFailure={setFailureOpen}
        />
      )}
      {openConciliationDialog && (
        <PaymentConciliationDialog
          open={openConciliationDialog}
          setOpen={setOpenConciliationDialog}
          payIn={payIn}
          setPayIn={setPayIn}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={fetchPayIns}
          pagination={pagination}
          setOpenBankAccountModal={setOpenBankAccountModal}
        />
      )}
      <CustomAlert
        open={success}
        title="Éxito"
        onClose={() => {
          setSuccess(false);
        }}
        setOpen={setSuccess}
        text={successText}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure}
        title="Error"
        onClose={() => {
          setFailure(false);
        }}
        setOpen={setFailure}
        text={failureText}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default PayIns;
