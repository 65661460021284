import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessDetails,
  getBusinessFactoringOperativeSummary,
} from '../../../../../lib/api';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import MainInfo from './components/MainInfo';
import { AuthState } from '../../../../../services/redux/authSlice';

interface Contact {
  id: number;
  name: string;
  email: string;
  phone: string;
  role: string;
  businessName: string;
  businessId: number;
}
interface BusinessDetails {
  businessIdentifier: string;
  region: string;
  commune: string;
  address: string;
  mainContact: Contact | null;
  size: string;
  uaf: Date | null;
  economicActivity: string;
  economicSector: string;
  page: string;
}

const Client = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>({
    businessIdentifier: '',
    region: '',
    commune: '',
    address: '',
    mainContact: null,
    size: '',
    uaf: null,
    economicActivity: '',
    economicSector: '',
    page: '',
  });
  const [operativeBusinessDetails, setOperativeBusinessDetails] = useState<any>(
    {},
  );

  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const setUp = async () => {
    if (!id) {
      alert('No se ha encontrado el id del cliente');
      return;
    }
    setLoading(true);
    const businessDetails = await getBusinessDetails(dispatch, id);
    const operativeBusinessDetailsResponse =
      await getBusinessFactoringOperativeSummary(dispatch, id);
    const businessDetailsResponse =
      businessDetails.length > 0 ? businessDetails[0] : {};
    setBusinessDetails(businessDetailsResponse);
    setOperativeBusinessDetails(operativeBusinessDetailsResponse || {});
    // const businessOperativeDetails = await getBusinessBankruptcyPosts(dispatch, Number(id));
    console.log(loading);
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      <div>
        <HeaderWithActions
          variant="body1"
          subHeader
          buttons={[]}
          title="Aquí encontrarás un resumen de la información de tu cliente."
        />
        <MainInfo
          businessDetails={businessDetails}
          setBusinessDetails={setBusinessDetails}
          setLoading={setLoading}
        />

        {/* {!validateCompanyTypes(user, 'Accountant') && (
          <div style={{ padding: '20px 0px' }}>
            <OperativeInfo
              operativeBusinessDetails={operativeBusinessDetails}
              setOperativeBusinessDetails={setOperativeBusinessDetails}
              setLoading={setLoading}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Client;
