import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import {
  createConciliationBatch,
  deleteCompanyPayIn,
} from '../../../../../../../../lib/api/conciliations';
import PaymentConciliationSummary from './SummaryContainer';
import PaymentConciliationAssgination from './Conciliate';
import FNCDialog from './FNC';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';
import Payment from '../Payment';

const PaymentConciliationDialog = (props: any) => {
  const {
    open,
    setOpen,
    payIn,
    setPayIn,
    setSuccessOpen,
    setFailureOpen,
    setOpenBankAccountModal,
    setUp,
    pagination,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [openEdit, setOpenEdit] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFNC, setOpenFNC] = useState(false);
  const [factoringDocumentsSuggested, setFactoringDocumentsSuggested] =
    useState([]);
  const generateConciliationSavePayload = () => {
    console.log(factoringDocumentsSuggested);
    return factoringDocumentsSuggested
      .map((doc: any) => ({
        documentId: doc.id,
        amount: doc.amountToConciliate,
        payInId: payIn.id,
      }))
      .filter((doc: any) => doc.amount > 0);
  };

  const submitPayIn = async () => {
    try {
      setLoading(true);
      const payload = generateConciliationSavePayload();
      console.log(payload);
      await createConciliationBatch(dispatch, payload);
      setOpen(false);
      setSuccessOpen('Pago conciliado correctamente');
      await setUp({ page: pagination.page, pageSize: pagination.pageSize });
    } catch (error) {
      console.log(error);
      setFailureOpen('Error al crear la conciliación');
    }
    setLoading(false);
  };

  const deletePayIn = async () => {
    try {
      setLoading(true);
      await deleteCompanyPayIn(dispatch, payIn.id);
      setOpen(false);
      await setUp({ page: pagination.page, pageSize: pagination.pageSize });
    } catch (error) {
      console.log(error);
      setFailureOpen('Error al borrar el pago');
    }
    setLoading(false);
  };

  const amountToConciliate = factoringDocumentsSuggested.reduce(
    (acc: number, doc: any) => acc + (Number(doc.amountToConciliate) || 0),
    0,
  );

  const disabled =
    loading || payIn.amount - payIn.amountConciliated - amountToConciliate < 0;
  console.log(
    disabled,
    `payIn.amount: ${payIn.amount}`,
    `payIn.amountConciliated: ${payIn.amountConciliated}`,
    `amountToConciliate: ${amountToConciliate}`,
    `payIn.amount - payIn.amountConciliated - amountToConciliate: ${
      payIn.amount - payIn.amountConciliated - amountToConciliate
    }`,
  );
  useEffect(() => {
    // setUpAccounts();
  }, []);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        {loading ? (
          <div style={{ minHeight: 200 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        ) : (
          <DialogContent>
            <PaymentConciliationSummary
              payIn={payIn}
              bigSetup={setUp}
              setOpen={setOpen}
            />
            <Divider />

            <PaymentConciliationAssgination
              payIn={payIn}
              setPayIn={setPayIn}
              setOpen={setOpen}
              bigSetup={setUp}
              setDocumentsSuggested={setFactoringDocumentsSuggested}
              documentsSuggested={factoringDocumentsSuggested}
            />
          </DialogContent>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 20,
          }}
        >
          <div style={{ display: 'flex', gap: 10 }}>
            <CustomButton
              onClick={() => setOpenDelete(true)}
              color="error"
              disabled={payIn.amountConciliated !== 0}
            >
              Borrar
            </CustomButton>
            <CustomButton
              onClick={() => {
                setOpenEdit(true);
              }}
              color="warning"
              disabled={payIn.amountConciliated !== 0}
            >
              Editar
            </CustomButton>
          </div>
          <div style={{ display: 'flex', gap: 10 }}>
            <CustomButton onClick={() => setOpen(false)} color="secondary">
              Salir
            </CustomButton>
            {validateCompanyTypes(user, 'FinancingHub') && (
              <CustomButton
                onClick={() => setOpenFNC(true)}
                color="success"
                disabled={
                  disabled || !validatePermissions(user, 'financing_fnc_new')
                }
              >
                FNC
              </CustomButton>
            )}
            <CustomButton
              onClick={() => submitPayIn()}
              color="primary"
              disabled={disabled}
            >
              Guardar
            </CustomButton>
          </div>
        </div>
        <FNCDialog
          open={openFNC}
          setBigOpen={setOpen}
          setOpen={setOpenFNC}
          setUp={setUp}
          payIn={payIn}
          setPayIn={setPayIn}
          setSuccessOpen={setSuccessOpen}
        />
        <Payment
          open={openEdit}
          setOpen={setOpenEdit}
          setOpenConciliation={setOpen}
          payIn={payIn}
          setPayIn={setPayIn}
          setUp={setUp}
          setOpenBankAccountModal={setOpenBankAccountModal}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogContent>
            <Typography variant="h4">Borrar pago #{payIn.id}</Typography>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 0px',
              }}
            >
              <Typography variant="body1">
                ¿Estás seguro de querer borrar este pago?
              </Typography>
            </div>
            <DialogActions>
              <CustomButton
                onClick={() => setOpenDelete(false)}
                color="secondary"
              >
                Cancelar
              </CustomButton>
              <CustomButton onClick={() => deletePayIn()} color="error">
                Borrar
              </CustomButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Dialog>
    </div>
  );
};

export default PaymentConciliationDialog;
