import React from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import CustomButton from '../Button';

type ButtonColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

export interface CustomButtonProps {
  name?: string;
  color: ButtonColor;
  action: () => void;
  disabled?: boolean;
  startIcon?: any;
  customButton?: any;
  hoverTooltip?: string;
}

interface HeaderWithActionsProps {
  buttons?: CustomButtonProps[];
  customElements?: any[];
  title?: any;
  titleButtons?: any;
  subHeader?: boolean;
  variant?: any;
  padding?: string;
  type?: 'pageHeader' | 'subHeader' | 'dialogHeader' | 'dialog';
  item?: any;
  customTitle?: any;
  fontWeight?: 'bold' | 'normal';
}
const headerTypePadding: any = {
  pageHeader: '24px 0px 24px',
  subHeader: '0px 0px 10px',
  dialogHeader: '8px 0px',
  dialog: '12px 20px 0px',
};

const HeaderWithActions: React.FC<HeaderWithActionsProps> = ({
  buttons,
  title,
  titleButtons,
  variant,
  type,
  customTitle,
  item,
  fontWeight = 'light',
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        alignItems: 'center',
        padding: type
          ? headerTypePadding[type]
          : headerTypePadding['pageHeader'],
      }}
    >
      <Grid item alignItems={'center'}>
        {title && (
          <Typography variant={variant || 'h1'} fontWeight={fontWeight}>
            {title}
          </Typography>
        )}
        {customTitle}
        {titleButtons && (
          <div style={{ display: 'flex', padding: '4px 0px' }}>
            {titleButtons.map((btn: any, index: any) => (
              <CustomButton
                key={index}
                variant="contained"
                size="small"
                disabled={btn.disabled}
                color={btn.color}
                onClick={btn.action}
                style={{ marginLeft: index > 0 ? 8 : 0 }} // add margin only from the second button
              >
                {btn.name}
              </CustomButton>
            ))}
          </div>
        )}
      </Grid>
      {/* Action Buttons */}
      <Grid item display={'flex'} alignItems={'center'} gap={'5px'}>
        <div style={{ marginRight: 8 }}>{item}</div>
        {buttons?.map((btn, index) => {
          if (!btn.customButton)
            return (
              <Tooltip key={index} title={btn.hoverTooltip || ''}>
                <CustomButton
                  variant="contained"
                  size="small"
                  disabled={btn.disabled}
                  color={btn.color}
                  onClick={btn.action}
                  style={{ marginLeft: index > 0 ? 8 : 0 }} // add margin only from the second button
                  startIcon={btn.startIcon}
                >
                  {btn.name}
                </CustomButton>
              </Tooltip>
            );
          return <>{btn.customButton}</>;
        })}
      </Grid>
    </Grid>
  );
};

export default HeaderWithActions;
