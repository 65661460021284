import React from 'react';
import InforPreview from '../../../../../../../../../common/InforPreview';
import SimulationDoneFrozen from '../../../../../../../../../common/SimulationDoneFrozen';
import DialogActionsWrapper from '../DialogActionWrapper';

const ShoppingCartFactoringActive = (props: any) => {
  const { setOpenShoppingCart, selectedRequest, setUp } = props;

  const infoDiv = (
    <InforPreview
      requestType="factoring"
      selectedRequest={selectedRequest}
      status="Activa"
      type="Recieved"
    />
  );

  return (
    <div>
      <div>
        <SimulationDoneFrozen
          selectedRequest={selectedRequest}
          infoDiv={infoDiv}
        />
        <DialogActionsWrapper
          type="factoring"
          onClose={() => setOpenShoppingCart(false)}
          selectedRequest={selectedRequest}
          setUp={setUp}
        />
      </div>
    </div>
  );
};

export default ShoppingCartFactoringActive;
