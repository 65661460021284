import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { formatDate, formatMoney } from '../../functions/utils';
import { Chip, Tooltip, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Email,
  Phone,
  PriceCheck,
  Circle,
  SmartToyOutlined,
  Report,
  AttachMoney,
} from '@mui/icons-material';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { LightTooltip } from '../WhiteTooltip';

export const formatDateToLocal = (value: any, short = true) => {
  const dateObj = new Date(value);

  const date = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
  const time = `${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}:${String(dateObj.getSeconds()).padStart(2, '0')}`;
  if (short) return `${date}`;

  return `${date} ${time}`;
};

export const colors: any = {
  'NO SOLICITADA': '#D7D7D7', // Grey
  Creada: '#3bc14a', // Green
  Cotizada: '#0F23DF', // Blue
  Revisada: '#30a14e', // A different shade of green
  'En revisión': '#E8C547', // Yellow
  Cedida: '#2d9cdb', // A different shade of blue
  Aceptada: '#34d058', // Another shade of green, slightly lighter
  Aprobado: '#28a745', // A more distinct green
  Aprobada: '#28a745', // A more distinct green
  'Faltan datos': '#dbab09', // Darker yellow
  Cancelada: '#D62839', // Red
  Gestionada: '#2563eb', // Another shade of blue, deeper
  Confirmada: '#2185d0', // Blue, slightly different
  Rechazada: '#e5534b', // A different shade of red
  Finalizada: '#2c974b', // Another green, darker
  Advertencia: '#ffc107', // Amber
  Vencida: '#1a73e8', // A unique blue
  Validando: '#007bff', // Bright blue
  'No solicitada': '#D7D7D7', // Grey, same as 'NO SOLICITADA' for consistency
  Asignada: '#4dade9', // Light blue
  Activa: '#28a745', // Fresh green
  Congelada: '#82a1b1', // Cold blue-grey
  OPEN: '#f39c12', // Bright orange
  CLOSED: '#27ae60', // Calm green
  Completada: '#D7D7D7', // Bright green
  PENDING: '#E8C547',
  REVIEWED: '#3bc14a',
  ACTIVATED: '#3bc14a',
  DEACTIVATED: '#D62839',
};

const fsStatusColor: any = {
  CREADO: '#E8C547',
  CONFIGURANDO: '#E8C547',
  ANALIZADO: '#0F23DF',
  ASIGNADO: '#3bc14a',
};

const colorsTurn: any = {
  'Tu turno': '#E8C547',
  Esperando: '#D7D7D7',
};

const conciliationStatusColor: any = {
  Pendiente: '#E8C547',
  Conciliado: '#3bc14a',
};

const debtStatusColor: any = {
  Vencida: '#D62839',
  Regulada: '#3bc14a',
  Convenio: '#3bc14a',
  Embargo: '#D62839',
  Pagada: '#3bc14a',
};
const colorsCession: any = {
  Transable: '#3bc14a',
  'NC Completa': '#D62839',
  Reclamada: '#D62839',
  Cedida: '#D7D7D7',
  Solicitud: '#2563eb',
};

function formatTime(minutes: number): string {
  if (minutes < 60) {
    return `${Math.floor(minutes)} M`;
  } else if (minutes < 1440) {
    // Menos de un día (24 horas * 60 minutos)
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${hours} H${hours > 1 ? 's' : ''} ${mins} M`;
  } else {
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    // const mins = minutes % 60;
    return `${days} día${days > 1 ? 's' : ''} ${hours} hora${hours !== 1 ? 's' : ''}`;
  }
}

export const cellFormat = (value: any, type?: any, canNull = false) => {
  if (!type || (value === null && !canNull)) return value;

  switch (type) {
    case 'cession-lights':
      if (value === 'No cedido')
        return (
          <Tooltip title={'No cedido'}>
            <Circle
              style={{
                color: '#E8C547',
                fontWeight: 'bold',
              }}
            />
          </Tooltip>
        );
      if (isNaN(new Date(value).getTime()))
        return (
          <Tooltip title={value}>
            <Circle
              style={{
                color: '#D62839',
                fontWeight: 'bold',
              }}
            />
          </Tooltip>
        );
      return (
        <Tooltip title={formatDate(value)}>
          <Circle
            style={{
              color: '#3bc14a',
              fontWeight: 'bold',
            }}
          />
        </Tooltip>
      );
    case 'cancel-lights':
      return (
        <Tooltip title={value ? cellFormat(value, 'date') : 'Sin fecha'}>
          <Circle
            style={{
              color: !value ? '#3bc14a' : '#E8C547',
              fontWeight: 'bold',
            }}
          />
        </Tooltip>
      );
    case 'confirm-lights':
      return (
        <Tooltip title={value ? cellFormat(value, 'date') : 'Sin fecha'}>
          <Circle
            style={{
              color: value ? '#3bc14a' : '#E8C547',
              fontWeight: 'bold',
            }}
          />
        </Tooltip>
      );
    case 'yes-no-circle':
      return (
        <Circle
          style={{
            color: value ? '#3bc14a' : '#E8C547',
            fontWeight: 'bold',
          }}
        />
      );
    case 'decision':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: colors[value],
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );

    case 'due':
      if (value)
        return (
          <Chip
            label="Vencida"
            style={{
              backgroundColor: '#D62839',
              color: 'white',
              fontWeight: 'bold',
            }}
          />
        );
      return (
        <Chip
          label="Pagada"
          style={{
            backgroundColor: '#3bc14a',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'due-relaxed':
      if (value)
        return (
          <Chip
            label="Vencida"
            style={{
              backgroundColor: '#D62839',
              color: 'white',
              fontWeight: 'bold',
            }}
          />
        );
      return <p></p>;
    case 'debt-status':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: debtStatusColor[value],
            color: 'white',
            fontWeight: 'bold',
            opacity: 1,
          }}
        />
      );
      return (
        <Chip
          label="Pagada"
          style={{
            backgroundColor: '#3bc14a',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'days':
      return value !== 1 ? `${value} días` : `${value} día`;
    case 'checked':
      if (value) return <CheckCircleIcon style={{ color: '#3bc14a' }} />;
      return <ErrorIcon style={{ color: '#D62839' }} />;
    case 'vission':
      if (value)
        return (
          <CheckCircleIcon
            style={{ color: '#0F23DF', textAlign: 'center', paddingLeft: 30 }}
          />
        );
      return null;
    case 'status':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: colors[value],
            color: 'white',
            fontWeight: 'bold',
            opacity: 1,
          }}
        />
      );
    case 'fs-status':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: fsStatusColor[value],
            color: 'white',
            fontWeight: 'bold',
            opacity: 1,
          }}
        />
      );
    case 'cession':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: colorsCession[value],
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'conciliation-status':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: conciliationStatusColor[value],
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'formatMinutes':
      return formatTime(value);
    case 'boolean-active-null':
      return value ? (
        <div style={{ color: '#3bc14a', display: 'flex', textAlign: 'right' }}>
          <CheckCircleOutlineIcon style={{ marginRight: '0px' }} />
        </div>
      ) : null;
    case 'boolean-exception-null':
      return value ? (
        <LightTooltip
          title={
            <div style={{ padding: '12px' }}>
              <Typography>
                Está solicitud no utiliza línea ya que es una <b>Excepción</b>.
              </Typography>
            </div>
          }
          placement="top"
        >
          <Report color="warning" style={{ cursor: 'pointer' }} />
        </LightTooltip>
      ) : null;
    case 'boolean-active':
      return value ? (
        <div
          style={{ color: '#3bc14a', display: 'flex', alignItems: 'center' }}
        >
          <CheckCircleOutlineIcon style={{ marginRight: '5px' }} />
          <b>Activo</b>
        </div>
      ) : (
        <div
          style={{ color: '#D62839', display: 'flex', alignItems: 'center' }}
        >
          <HighlightOffIcon style={{ marginRight: '5px' }} />
          <b>Inactivo</b>
        </div>
      );
    case 'turn':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: colorsTurn[value],
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'sii-connection':
      return value ? (
        <Chip
          label="Conectado"
          style={{
            backgroundColor: '#3bc14a',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      ) : (
        <Chip
          label="No conectado"
          style={{
            backgroundColor: '#E8C547',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'boolean-yes':
      return value ? 'Si' : 'No';
    case 'date':
      return value ? formatDate(value) : 'Sin información';
    case 'date-colored':
      return (
        <Chip
          label={formatDate(value)}
          style={{
            backgroundColor: '#E8C547',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'date-colored-ok':
      return (
        <Chip
          label={formatDate(value)}
          style={{
            backgroundColor: '#3bc14a',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'date-large':
      return formatDateToLocal(value, false);
    case 'money':
      return formatMoney(value);
    case 'money-K':
      return formatMoney(parseInt(value) * 1000);
    case 'money-M':
      return `${formatMoney(parseInt(value) / 1000000)}M`;
    case 'money-colored':
      if (value === 0) return formatMoney(value);
      return (
        <Chip
          label={formatMoney(value)}
          style={{
            backgroundColor: '#E8C547',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'ratio':
      return isNaN(parseFloat(value)) ? value : parseFloat(value).toFixed(2);
    case 'date-hours':
      return formatDate(value);
    case 'percentage':
      return `${(value * 100).toFixed(2)}%`;
    case 'percentage-soft':
      return `${value}%`;
    case 'collection-invoice-status':
      return (
        <Chip
          label={value === 'OPEN' ? 'En proceso' : 'Cerrada'}
          style={{
            backgroundColor: colors[value],
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'collection-type':
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          {value === 'CALL' && (
            <>
              <Phone style={{ marginRight: 5 }} /> Llamada{' '}
            </>
          )}
          {value === 'EMAIL' && (
            <>
              <Email style={{ marginRight: 5 }} /> Correo{' '}
            </>
          )}
          {value === 'CLOSED' && (
            <>
              <PriceCheck style={{ marginRight: 5 }} /> Pago completado{' '}
            </>
          )}
          {['auto-enrollment', 'auto-unenrollment'].includes(value) && (
            <>
              <SmartToyOutlined style={{ marginRight: 5 }} /> Cobranza
              Automática{' '}
            </>
          )}
          {![
            'CALL',
            'EMAIL',
            'CLOSED',
            'auto-enrollment',
            'auto-unenrollment',
          ].includes(value) && value}
        </span>
      );
    case 'invoice-type':
      return (
        <>
          {value === 'cession' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <SwapHorizontalCircleOutlinedIcon
                style={{ marginRight: '5px' }}
              />
              <span>Cesión</span>
            </div>
          )}
          {value === 'invoice' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ReceiptIcon style={{ marginRight: '5px' }} />
              <span>Factura</span>
            </div>
          )}
          {value === 'credit' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <AttachMoney style={{ marginRight: '5px' }} />
              <span>Crédito</span>
            </div>
          )}
        </>
      );
    case 'auto-collection-status':
      return (
        <>
          {value && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <SmartToyOutlined style={{ marginRight: '5px' }} />
              <span>Activo</span>
            </div>
          )}
        </>
      );
    case 'pn-invoices':
      return (
        <ul>
          {value.map((invoice: any, index: number) => (
            <li key={index}># {invoice.folio}</li>
          ))}
        </ul>
      );
    case 'pn-business':
      return value.name;
    case 'pn-status':
      return (
        <Chip
          label={
            value === 'PENDING'
              ? 'Pendiente'
              : value === 'REVIEWED'
                ? 'Revisada'
                : value
          }
          style={{
            backgroundColor: colors[value],
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'isFather':
      return (
        <span style={{ fontSize: 16 }}>
          <b>{value}</b>
        </span>
      );
    case 'paid':
      return (
        <Chip
          label={value ? 'Pagada' : 'Pendiente'}
          style={{
            backgroundColor: value ? '#3bc14a' : '#E8C547',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    case 'invoice-remaining-amount':
      return (
        <>
          {value > 0 && (
            <Chip
              label={formatMoney(value)}
              style={{
                backgroundColor: '#E8C547',
                color: 'white',
                fontWeight: 'bold',
              }}
            />
          )}
          {value === 0 && (
            <Chip
              label="Factura pagada"
              style={{
                backgroundColor: '#3bc14a',
                color: 'white',
                fontWeight: 'bold',
              }}
            />
          )}
        </>
      );
    case 'alert':
      return (
        <Circle
          color={
            value === 'Peligro' || value === 'Mega peligro'
              ? 'error'
              : value === 'Advertencia'
                ? 'warning'
                : 'success'
          }
        />
      );
    case 'report':
      return (
        <div style={{ padding: '7.5px 0px' }}>
          <span>{value}</span>
        </div>
      );
    case 'documentAlert':
      if (value === 'creditNote') {
        return 'ceca';
      } else if (value === 'cessionReceived') {
        return 'Recepción de cesión';
      } else if (value === 'canceledInvoice') {
        return 'Factura anulada';
      }
      return value;
    case 'collectionManagementStateName':
      return (
        <Chip
          label={value['name']}
          style={{
            color: 'black',
            fontWeight: 'bold',
          }}
        />
      );
    case 'chip':
      return (
        <Chip
          label={value}
          style={{
            backgroundColor: '#3bc14a',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      );
    default:
      return value;
  }
};
