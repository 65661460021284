import { requestHandler } from '../../api';

export const fetchAllGiver = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest`,
    dispatch,
  });
  if (response) return response.data;
};

export const fetchDetails = async (dispatch: any, creditId: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest/${creditId}`,
    dispatch,
  });
  if (response) return response.data;
};

export const createCredit = async (dispatch: any, creditDetails: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditRequest`,
    dispatch,
    data: creditDetails,
  });
  if (response) return response.data;
};

export const updateCreditRequestQuote = async (
  dispatch: any,
  creditDetails: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditRequest/${creditDetails.id}/quote`,
    dispatch,
    data: creditDetails,
  });
  if (response) return response.data;
};

export const getCreditRequestMissingApprovals = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest/${data.id}/missingApprovals`,
    dispatch,
  });
  if (response) return response.data;
};

export const editCreditRequestComitee = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/creditRequest/${id}/comitee`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const deleteCreditRequestApproval = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/creditRequest/${id}/approval`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const getCreditRequestApprovals = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest/${id}/approval`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCreditRequestBankAccounts = async (
  dispatch: any,
  data: any,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/creditRequest/${data.id}/bankAccounts`,
    dispatch,
  });
  if (response) return response.data;
};

export const saveCreditRequestPayments = async (dispatch: any, data: any) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditRequest/${data.id}/payments`,
    dispatch,
    data,
  });
  if (response) return response.data;
};

export const executeCreditRequestPayouts = async (
  dispatch: any,
  id: number,
  data: any,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/creditRequest/${id}/payouts/execute`,
    data,
    dispatch,
  });
  if (response) return response.data;
};

export const deleteCreditLine = async (dispatch: any, id: number) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/creditRequest/${id}`,
    dispatch,
  });
  if (response) return response.data;
};
