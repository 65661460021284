import React from 'react';
import CollectionsIndicators from './components/Indicators';
import CollectionDocuments from './components/Documents';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { useSelector } from 'react-redux';

const CollectionsHome = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const canShowIndicators = validateCompanyTypes(user, 'FinancingHub');
  const canHaveSubordinates = validatePermissions(user, 'have_subordinates');
  return (
    <div>
      {canShowIndicators && <CollectionsIndicators />}
      <CollectionDocuments canHaveSubordinates={canHaveSubordinates} />
    </div>
  );
};

export default CollectionsHome;
