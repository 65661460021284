import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import EnhacedBox from '../../../../../../lib/designSystem/BigBox';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { getCollectionNotificationsForMonth } from '../../../../../../lib/api/collectionNotifications';
import { useDispatch } from 'react-redux';
import { LightTooltip } from '../../../../../../lib/designSystem/WhiteTooltip';
import DocumentSummary from './components/DocumentSummary';
import { ChevronRight, Info } from '@mui/icons-material';

interface Notification {
  id: string;
  day: number;
  type: keyof typeof NOTIFICATION_TYPES;
  sent: boolean;
  company: string;
  documentId: string;
}

const NOTIFICATION_TYPES = {
  issuedInvoices: 'Notificación de facturas emitidas',
  documentCession: 'Notificación de cesión',
  documentCessionReminder: 'Notificación próximo vencimiento',
  documentCessionDue: 'Notificación de vencimiento',
};

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});
const initialValue = dayjs();

function CalendarDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[] },
) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  const isHighlighted =
    !outsideCurrentMonth && highlightedDays.includes(day.date());

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isHighlighted ? '🔴' : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function NotificationsCalendar() {
  const [isLoading, setIsLoading] = useState(true);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [selectedDay, setSelectedDay] = useState<number>(dayjs().date());
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const dispatch = useDispatch();

  const fetchHighlightedDays = async (date: Dayjs) => {
    try {
      const fetchData = await getCollectionNotificationsForMonth(
        dispatch,
        date.format('MM-YY'),
      );
      setHighlightedDays(fetchData.map((item: any) => item.day));
      setNotifications(fetchData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHighlightedDays(initialValue);
  }, []);

  const handleMonthChange = (date: Dayjs) => {
    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const handleDayChange = (date: Dayjs) => {
    setSelectedDay(date.date());
    setSelectedDate(date);
    getNotificationsForDay(notifications, date.date());
  };

  const getNotificationsForDay = (
    notifications: Notification[],
    day: number,
  ) => {
    const notificationsForDay = notifications.filter(
      (item) => item.day === day,
    );
    console.log(notificationsForDay);
    return notificationsForDay;
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '23px 0 0 0' }}>
      <EnhacedBox>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                defaultValue={selectedDate}
                loading={isLoading}
                onMonthChange={handleMonthChange}
                onChange={handleDayChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                  day: CalendarDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                  } as any,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5">
              Correos para {selectedDate.format('DD/MM/YYYY')}
            </Typography>
            <Divider />
            <List>
              {getNotificationsForDay(notifications, selectedDay).map(
                (notification) => (
                  <ListItem
                    key={notification.id}
                    secondaryAction={
                      <LightTooltip
                        placement="left"
                        title={
                          <DocumentSummary
                            documentId={notification.documentId}
                          />
                        }
                      >
                        <IconButton edge="end" aria-label="ver más">
                          <Info />
                        </IconButton>
                      </LightTooltip>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {notification.sent ? (
                          <LightTooltip
                            title={
                              <Typography style={{ padding: '4px' }}>
                                Correo enviado
                              </Typography>
                            }
                          >
                            <MarkEmailReadIcon color="success" />
                          </LightTooltip>
                        ) : (
                          <LightTooltip
                            title={
                              <Typography style={{ padding: '4px' }}>
                                Correo pendiente
                              </Typography>
                            }
                          >
                            <ScheduleSendIcon color="info" />
                          </LightTooltip>
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={NOTIFICATION_TYPES[notification.type]}
                      secondary={notification.company}
                    />
                  </ListItem>
                ),
              )}
              {getNotificationsForDay(notifications, selectedDay).length ===
                0 && (
                <Typography variant="body1">
                  No hay correos para esta fecha
                </Typography>
              )}
            </List>
          </Grid>
        </Grid>
      </EnhacedBox>
    </div>
  );
}
