import React, { useState } from 'react';
import CenteredCircularProgress from '../../../../../../../../../../../lib/designSystem/CircularProgress';
import ShoppingCartAccepted from './components/2_ShoppingCartAccepted';
import ShoppingCartInReview from './components/1_ShoppingCartInReview';
import ShoppingCartActive from './components/3_ShoppingCartActive';

export interface FactoringRequest {
  id: number;
  status: string;
  sender: string;
  recipient: string;
  validUntil: Date;
  paymentDate: Date;
  amount: number;
  extraData: boolean;
  relation?: any;
  businessId: number;
}

const Summary = (props: any) => {
  const {
    shoppingCartType,
    openShoppingCart,
    setOpenShoppingCart,
    openRequest,
    setSuccessOpen,
    setFailureOpen,
    setUp,
    handleOpenDeleteConfirmModal,
  } = props;
  const [loading, setLoading] = useState(false);
  console.log('selectedRequest', openRequest);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      {loading ? <CenteredCircularProgress type="dialog" /> : <div></div>}
      {shoppingCartType === 'En revisión' && (
        <ShoppingCartInReview
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={setUp}
          setLoading={setLoading}
          handleOpenDeleteConfirmModal={handleOpenDeleteConfirmModal}
        />
      )}
      {shoppingCartType === 'Aceptada' && (
        <ShoppingCartAccepted
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          updateStatus
          setUp={setUp}
          setLoading={setLoading}
          handleOpenDeleteConfirmModal={handleOpenDeleteConfirmModal}
        />
      )}
      {(shoppingCartType === 'Activa' ||
        shoppingCartType === 'Congelada' ||
        shoppingCartType === 'Completada') && (
        <ShoppingCartActive
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          openRequest={openRequest}
          setSuccessOpen={setSuccessOpen}
          setUp={setUp}
          setLoading={setLoading}
          handleOpenDeleteConfirmModal={handleOpenDeleteConfirmModal}
        />
      )}
    </div>
  );
};

export default Summary;
